import axios, { AxiosInstance } from 'axios'
import {getUserCode } from "./common";

// eslint-disable-next-line
const config = require('../../config')
const { baseURL } = config[process.env.NODE_ENV]

const service: AxiosInstance = axios.create({
  baseURL,
  timeout: 5000
})

service.interceptors.request.use(
  config => {
    config.headers['token'] = process.env.NODE_ENV === 'development'?'NDo3QjU2RjRFQUM5NjU0OTQ5RTg0MjFFRDk1NTM1MTcyRA==':localStorage.getItem('token')
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if(res.code===10000){
      localStorage.setItem('token','')
      localStorage.setItem('mem',JSON.stringify([]))
      getUserCode();
    }else if(res.code !== 0) {
      return Promise.reject(res)
    } else {
      return response.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
