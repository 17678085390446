import router from './router';
import { showToast } from 'vant'
import {getUserCode } from "./utils/common";
import {getUserInfoWithCode } from "./api/user";

//路由加载前判断登录状态

router.beforeEach((to, from, next) => {
    // showToast('身份校验')
    // return false
    //获取token，判断登录状态
    const token=localStorage.getItem('token')
    // token=null
//在这里区分正式与测试环境
if(process.env.NODE_ENV === 'development'){
    next()
}else{

    if(!token){
        //判断路由中是否存在wxcode，利用wxcode获取用户的授权信息
        if(to.query.wxcode){
            getUserInfoWithCode({wxcode:to.query.wxcode,redirect_uri:'https://wkmeet-dev-h5.91tests.net'}).then(res=>{
                localStorage.setItem('token',res.token)
                localStorage.setItem('mem',JSON.stringify(res))
                next()
            }).catch(err =>{
                showToast('授权失败')
                return false;
              })
        }else{

            showToast('登录凭证已失效,请重新登录')
            getUserCode();
        }

    }else{
        if(to.query.clean){
            localStorage.setItem('token','')
            getUserCode();
        }
        next()

    }
}


})
